.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  width: 36ch;
  text-align: center;
}

.modal-container input {
  width: 20ch;
  margin: 1rem 0ch;
  font-size: 1rem;
  padding: 0.5rem;
  border: #dfdfdf 1px solid;
  border-radius: 0.25rem;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.modal-overlay h2 {
  font-size: 0.875rem;
  color: #2d2d2d;
  margin: 0rem 0 0rem 0;
}

.overridePrice {
  color: #1380b1;
}
