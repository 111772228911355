.part-pricing__container {
  width: 30rem !important;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.part-pricing__pageHeader {
  width: 100%;
}

.part-pricing__pageHeader h1 {
  font-size: 1.125rem;
  margin: 1rem 1rem 1rem 0;
}

.part-pricing__pageHeader h1 > span {
  font-size: 0.75rem;
  font-weight: 100;
  margin-left: 1rem;
}

.part-pricing__section > h1 {
  font-size: 0.875rem;
  color: #2d2d2d;
  margin: 2rem 0 1rem 0;
}

.part-pricing__note {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.part-pricing__note > span {
  font-weight: bold;
  font-variant: small-caps;
  color: #d40000;
}

.part-pricing__input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.part-pricing__input > label {
  font-weight: 600;
  margin-top: 1.25rem;
  color: var(--neutral-6);
  font-size: 0.875rem;
}

.part-pricing__input input[type="number"] {
  font-size: 1rem;
  padding: 0.5rem;
  margin-top: 1.125rem;
  border: #dfdfdf 1px solid;
  border-radius: 0.25rem;
}

.part-pricing__csr-input {
  width: 50%;
  max-width: 16ch;
  display: inline-block !important;
}

.part-pricing__calculated {
  margin: 1rem 0 0 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--neutral-6);
  cursor: pointer;
}

.part-pricing__calculated > span {
  font-weight: normal;
  font-variant: small-caps;
  font-size: 0.75rem;
}

.part-pricing__tab-control {
  width: 100%;
}

.part-pricing__tablist {
  font-size: 0.875rem;
  font-weight: 400;
  justify-content: center;
  padding-left: 0;
  border-bottom: 1px solid var(--neutral-2);
}

.part-pricing__details-container {
  font-size: 0.875rem;
}
.part-pricing__details-container p {
  margin-top: 0.5rem;
}

.part-pricing__pricing-details h3 {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.part-pricing__pricing-details > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 0.875rem;
}

.part-pricing__pricing-details > div > label {
  font-size: 0.875rem;
  text-align: right;
}

.part-pricing__pricing-details > div > p {
  font-size: 0.875rem;
  font-weight: bold;
}

.part-pricing__source {
  margin-left: 1.5rem;
  padding-bottom: 0;
}

.react-select__clear-indicator {
  color: var(--neutral-3);
}

.react-select__dropdown-indicator {
  color: var(--neutral-3);
  /* color: #1380b1 !important; */
  transform: scale(1.125);
}

.react-select__control {
  margin-top: 1.25rem;
}

.part-pricing__results h3 {
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1.25rem;
}

.part-pricing__results p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.25rem;
  text-align: center;
  margin: 1rem 0.75rem 0.75rem 0.75rem;
}

.part-pricing__result-prices {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;
  align-content: stretch;
  justify-items: stretch;
  margin: 1rem 0 0rem 0;
  border: 1px solid var(--neutral-2);
}

.part-pricing__result-prices > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.part-pricing__result-prices .part-pricing__label {
  padding: 0.5rem 0.5rem 0 0.5rem;
  font-variant: small-caps;
  font-size: 0.75rem;
}

.part-pricing__result-prices .part-pricing__price {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  color: var(--neutral-6);
}

.part-pricing__result-prices .part-pricing__highlight {
  font-size: 1rem;
  background-color: var(--primary-teal);
  color: white;
}
.part-pricing__submenu {
  text-align: right;
  margin-top: 0.25rem;
  font-size: 0.75rem;
}
.part-pricing__checkbox {
  margin-top: 0;
  font-size: 0.875rem;
  padding: 0rem;
}

.nerdy-details {
  margin-top: 1rem !important;
  font-size: 0.875rem;
}
.nerdy-details a {
  text-align: left;
  display: block;
}

.part-pricing__input-toggle {
  font-weight: normal;
  font-size: 0.75rem;
  margin-left: 2rem;
}

.part-pricing__nonDefault {
  border: 1px solid #e9e9e9;
  border-top: none;
  padding: 0.5rem 1rem;
  background-color: #ffffe0;
}
.part-pricing__nonDefault p {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.part-pricing__edit {
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.part-pricing__versus {
  font-size: 0.75rem;
  font-weight: normal;
  font-style: italic;
  margin-left: 0.5rem;
}
.part-pricing__notFound {
  font-weight: normal;
  cursor: pointer;
}
.part-pricing__notFound button {
  padding: 0.5rem;
  margin: 0.5rem;
}
.part-pricing__notFound p {
  margin-block: 0.5rem;
  line-height: 1.4;
}
