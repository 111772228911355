:root {
  /* PRIMARIES */
  --primary-teal: #1380b1;
  --tangerine: #f06721;
  --primary-black: #000;
  --white: #fff;
  --pale-gray: #f7f7f7;

  /* GRAYS */
  --neutral-1: #f6f6f6;
  --neutral-2: #dfdfdf;
  --neutral-3: #b2b2b2;
  --neutral-4: #707070;
  --neutral-5: #4a4a4a;
  --neutral-6: #2d2d2d;

  /* ALERTS */
  --success: #00aa00;
  --error: #e50000;
  --caution: #ffd041;
}

body {
  font-family: Roboto, Inter, Arial, Helvetica, sans-serif;
  padding: 1rem;
  max-width: 800px;
  margin: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
div.warning {
  font-size: 75%;
  margin-block: 0.5rem -0.5rem;
  color: red;
}

.alert {
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Roboto;
  background-color: #d4000007;
  border: 1px solid #d4000080;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 2rem 1rem 0;
  color: #d40000;
  width: 100%;
}

a {
  color: var(--primary-teal) !important;
}
