.auth p {
    margin-block: 0.5rem;
  }
  
  .auth input[type="email"] {
    margin: 1rem 1rem 0.25rem 0;
    font-size: 1.25rem;
    padding: 0.25rem;
    width: 40ch;
  }
  
  .auth button {
    margin-top: 1rem;
    padding: 0.25rem;
  }
  
  .auth h2 {
    margin-top: 2rem;
  }
  