.drawer__main {
  position: relative;
  border: 1px solid var(--neutral-2);
  margin: 16px 0;
  transition: max-height 0.3s linear, border 0.3s linear,
    padding-top 0.3s linear, padding-bottom 0.3s linear;
}

.drawer__content {
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: max-height 0.3s linear;
}

.drawer__handle {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 10px;
  cursor: pointer;
  transition: border 0.3s linear;
}

.drawer__handle > button {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: calc(50% - 50px);
  top: 50%;
  transform: translateY(-40%);
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background-color: white;
  color: var(--primary-teal);
  font-weight: bold;
  width: 100px;
  height: 25px;
  padding: 4px 8px;
}
