.review-markups__container h1 {
  font-size: 1.125rem;
}

.review-markups__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9; */
  font-size: 0.875rem;
}

.review-markups__header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #e0e0e0;
  border-bottom: 2px solid #ccc;
  font-weight: bold;
}

.review-markups__row {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}

.review-markups__row div,
.review-markups__header div {
  flex: 1;
  text-align: center;
  padding: 0.25rem;
}

/* Specific column widths */
.partstown .review-markups__header div:first-child,
.partstown .review-markups__row div:first-child,
.vendors .review-markups__header div:first-child,
.vendors .review-markups__row div:first-child {
  flex: 3 1 6rem; /* Grow factor 3, Shrink factor 1, Min width 6rem */
  text-align: left;
}

.partstown .review-markups__header div:not(:first-child),
.partstown .review-markups__row div:not(:first-child),
.vendors .review-markups__header div:not(:first-child),
.vendors .review-markups__row div:not(:first-child) {
  flex: 1 1 4rem; /* Grow factor 1, Shrink factor 1, Min width 2rem */
  text-align: right;
}

.pricing .review-markups__header div,
.pricing .review-markups__row div {
  flex: 1 1 4rem;
  text-align: right;
  padding: 0 0.25rem;
  margin: 0 0.5rem;
}

.review-markups__container label {
  padding: 0.25rem;
}

.review-markups__discount {
  font-weight: bold;
}
.review-markups__avgDiscount {
  font-weight: normal;
}

.review-markups__variance-positive {
  color: rgb(4, 235, 4);
  font-weight: bold;
}

.review-markups__variance-negative {
  color: red;
  font-weight: bold;
}

.return-previous,
.review-markups-link {
  float: right;
  font-weight: normal;
  font-size: 0.75rem;
  color: blue; /* Simulate link color */
  text-decoration: underline; /* Simulate link underline */
  background: none; /* Remove background */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer */
  margin: 0 0 0 1rem;
  padding: 0; /* Remove padding to mimic a link */
}

.return-previous:hover {
  /*text-decoration: none; /* Optionally remove underline on hover for a common link effect */
  color: darkblue; /* Darken the color slightly on hover */
}

.review-markups__over100 {
  background-color: #ffffe0;
}

.custom-markup {
  background-color: #ffffe0;
}
